.main{
    display: block;
    float: right;
    .totalnum{
        float: left;
        line-height: 28px;
    }
    ul{
        &:last-child{
            top: auto;
            bottom: 29px;
            z-index: 9999999;
        } 
    }
}
.page{
    list-style: none;
    padding:0;
    margin-left: 10px;
    float: left;
    li{
        
        float:left;
        border:1px solid #e6e6e6;
        text-align: center;
        color:#333;
        cursor:pointer;
        height: 28px;
        line-height: 28px;
        min-width: 29px;
        margin: 0 4px;
        border-radius: 4px;

        &:first-of-type,&:last-of-type{
            padding:0 5px;
            width: 29px;
            display: inline-block;
            height: 28px;
            line-height: 28px;
            background: #FFF;
            border: 1px solid #bfbfbf;
            border-radius: 2px;
            vertical-align: middle;
            opacity: .6;
            background-size: 29px 28px;
            span{
                line-height: 28px;
                width: 100%;
                float: left;
            }
        }

        &:hover{
            border: 1px solid #428bca;
            color: #07e;
        }
        &:first-of-type{
            margin-left: 0;
        }

        &:last-of-type{
            margin-right: 0;
        }
        &::selection {
            background-color: transparent;
        }
    }
}
.select_box, .go_change{
    float: left;
    margin-left: 10px;
}
.go_change{
    line-height: 28px;
}
.active{
    color:#fff !important;
    background: #3B99FC;
    border-color:#428bca !important;
    cursor: default !important;
}

// 是否存在更多
.nomore{
    color:#b5b5b5 !important;
    cursor: not-allowed !important;
}
.nomore:hover{
    border: 1px solid #bfbfbf !important;
}

// 省略符
.ellipsis{
    border:none !important;
    margin:0 10px;
    width: 15px !important;
    cursor: default !important;
    position: relative;
    cursor: pointer;
    &:hover{
        color: #333 !important;
        .goFive{
            display: block !important;
        }
    }
    .goFive{
        position: absolute;
        top: 0;
        line-height: 28px;
        z-index: 999;
        cursor: pointer;
        color: #07e !important;
        background: #FFF;
        display: none;
        width: 28px;
        text-align: center;
    }
}


// 下拉菜单
.bar{
    display: flex;
    justify-content:space-between;
    align-items:flex-start;
    color:#666;
    span{
        font-size: 12px;
    }
}
.select{
    margin-left:4px;
    padding: 0 5px;
    height: 28px;
    float: right;
    cursor: pointer;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #D3D9E0;
    border-radius: 2px;
    line-height: 28px;

    p{
        float: left;
        width: 100%;
        height: 100%;
    }
    ul{
        width:calc(100% + 2px);
        cursor: pointer;
        bottom: 29px;
        position: absolute;
        background: #FFFFFF;
        border: 1px solid #D3D9E0;
        border-radius: 2px;
        left: -1px;
        z-index: 10;
        padding: 5px 0;
        
        li{
            text-align: center;
            width: 100%;
            height: 24px;
            line-height: 24px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            &:hover{
                background: #E7F4FD;;
            }
            &:active{
                background: #F2F2F2;
            }
            
        }
        
    }
}
// 收起状态
ul.hide{
    overflow: hidden;
    display: none;
}
// li标签选中状态
li.gray{
    background: #F2F2F2;
}