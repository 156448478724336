.main{
    .input_box{
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        input{
            width: 100%;
            padding: 0 5px;
            color:#28334B;
            font-size: 12px;
        }
        textarea{
            width: 100%;
            padding: 5px;
        }
        .bubble{
            position: absolute;
            text-align: center;
            min-width: 30px; 
            display: inline-block;
            padding:0 10px;
            left: 50%;
            height: 28px;
            background: #F44024;
            border-radius: 2px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 28px;
            opacity: 0.9;
            top: -32px;
            text-overflow: ellipsis;
            white-space:nowrap;
            filter: alpha(opacity=90);
            &:after{ 
                position: absolute;
                content: "";
                width: 0; 
                height: 0; 
                left: 50%; 
                margin-left: -5px; 
                bottom: -5px; 
                border-left: 5px solid transparent; 
                border-right: 5px solid transparent; 
                border-top: 5px solid #F44024;}
                
        }
    }
    .error_text{
        color: #F44024;
        font-size: 12px;
        line-height: 18px;
        margin-top: 5px;
        display: inline-block;
    }
    .error{
        border-color: #F44024 ;
    }
}