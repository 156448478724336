.upload_button_main{
    >span{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        height: 28px;
    }
    // antd上传组件样式兼容
    .ant-upload-list{
        height: 28px ;
        line-height: 28px ;
        &>div:nth-child(2){
            display: none;
        }
        .anticon-loading{
            display: none;
        }
        .ant-upload-list-item-progress{
            padding-left: 5px;
            top: 12px;
            position: absolute;
    
        }
        .ant-progress-bg{
            background-color: #3B99FC;
        }
    }
    .ant-upload-list-item{
        &:hover{
            .ant-upload-list-item-info{
                background-color: #FFF;
            }
            .ant-upload-list-item-card-actions{
                display: inline-block;
            }
            .ant-upload-list-item-card-actions{
                color: #F44024;
            }
        }
        height: 28px;
        margin-top: auto;
        .ant-upload-list-item-card-actions{
            z-index: 90;
            cursor: pointer;
            display: none;
            svg{display: none}
            font-family: "font_family";
            font-size: 14px;
            line-height: 30px;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            &:hover{
                color: #F44024;
            }
            &:before{
                content: "\e77d";
                position: absolute;
                left: 5px;
            }
            
            .ant-btn:hover{
                opacity: 0;
            }
        }
    }
    .ant-upload-list-item-info{
        padding-left: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        height: 28px;
    }
    .ant-upload-list-text{
        max-width: 200px;
    }
    .ant-upload-list-item-name{
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        padding-right: 14px;
        padding-left: 5px;
    }
    .ant-tooltip-content{
        display: none;
    }
    .anticon-paper-clip{
        display: none;
    }
}

