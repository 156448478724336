.main{
    width: 360px;
    height: 100%;
    transform: translateX(110%);
    position: fixed;
    top: 0;
    right: 0px;
    background: #fff;
    box-shadow: -1px 0px 10px 0px rgba(0,0,0,0.2);
    transition: all 0.3s;
    z-index: 999999;
    .title{
        width: 100%;
        text-align: center;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        position: relative;
        border-bottom: 1px solid #E1E1E6;
        >span{
            font-size: 14px;
            width: calc(100% - 30px);
            float: left;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .back{
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            position: absolute;
            top: 0px;
            right: 0px;
            label{
                width: 100%;
                cursor: pointer;
                height: 100%;
                font-size: 20px;
                float: left;
                color: #466080;
                &:hover{
                    color: #3B99FC;
                }
            }
        }   
    }

}
.show{
    transform: translateX(0);
}
