.main{
    .check_box{
        display: inline-block;
        >span,>label{
            line-height: 18px;
            float: left;
        }
        .icon{
            margin-right: 5px;
            cursor: pointer;
            color: #A3B1C7;
            
        }
        .val{
            margin-right: 5px;
        }
    }
    .check_box_inline_block{
        display: inline-block;
    }
    .check_box_group{
        display: block;
    }
    .children_group{
        margin-left:20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .disable{
        opacity: .6;
        background: #FFF;
        color: #b5b5b5 !important;
        cursor: not-allowed !important;
        span{
            color: #b5b5b5 !important;
            cursor: not-allowed !important;
        }
    }
}

