.main{
    display: flex;
    justify-content:space-between;
    align-items:flex-start;
    color:#28334B;
    font-size: 12px;
    .select{
        height: 28px;
        float: right;
        cursor: pointer;
        position: relative;
        background: #FFFFFF;
        border: 1px solid #D3D9E0;
        border-radius: 2px;
        line-height: 28px;
        &：active{
            border-color:#d3d9e0;
        }
        .value_box{
            height: 100%;
            border: 0;
            line-height: 26px;
            
        }
        .lastCheck,.maskingCheck{
            position: absolute;
            width: calc(100% - 20px);
            height: 100%;
            border: 0 none;
            padding-left: 5px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            z-index: 0;
            background-color: #FFF;
            left: 0;
            &.canserch{
                cursor: text;
            }
        }
        .maskingCheck span{
            color: #8da2b5;
        }
        .defaultVal{
            color: #28334B;
            float: left;
            width: 100%;
            height: 100%;
            border: 0 none;
            padding: 0 20px 0 5px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        i{
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 100%;
            text-align: center;
            cursor: pointer;
        }
        ul{
            width: calc(100% + 2px);
            cursor: pointer;
            top: 29px;
            position: absolute;
            background: #FFFFFF;
            border: 1px solid #D3D9E0;
            border-radius: 2px;
            left: -1px;
            z-index: 10;
            padding: 5px 0;
            max-height: 200px;
            overflow-y: auto !important;
            li{
                &:first-of-type{
                    display: block !important;
                }
                text-align: left;
                width: 100%;
                padding: 0 10px;
                height: 24px;
                line-height: 24px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                &:hover{
                    background: #E7F4FD;
                }
                &:active{
                    background: #F2F2F2;
                }
                
            }
            
        }
    }
}

// 收起状态
ul.hide{
    overflow: hidden;
    display: none;
}

// li标签选中状态
li.gray{
    background: #F2F2F2 !important;
}

// 暂无数据
.nodata{
    display: none;
    cursor:default !important;
    &:hover{
        background-color: #FFF !important;
    }
}

// 禁用
.disableAll{
    opacity: .6;
    cursor: not-allowed !important;
    .defaultVal{
        background: #FFF;
        color: #b5b5b5 !important;
    }
    .lastCheck{
        cursor: not-allowed !important;
    }
    i{
        cursor: not-allowed !important;
    }
    input{
        cursor: not-allowed !important;
    }
    ul{
        display: none !important;
        z-index: 10;
    }
}

.disable{
    opacity: .6;
    cursor: not-allowed !important;
    &:hover{
        background-color: #FFF !important;
    }
}