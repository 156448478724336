.main{
    margin-bottom: 20px;
    .title_bar{
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #D8DFEA;
        margin: 0;
        .title{
            font-size:18px;
            color:#28334B;
            line-height:40px;
            font-weight: normal;
            float: left;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        .buttons{
            float: right;
            line-height: 40px;
        }
    
    }
    .explan_text{
        margin-top : 5px;
        line-height: 18px;
        font-size: 12px;
        display: inline-block;
        color: #62778C;
    }
}