body{
    font-size: 12px;
    margin: 0;
}
#mainContent{
    position: absolute;
    left: 60px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    .mainRight{
        height: 100%;
        min-height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        position: relative;
    }

    .subnav_check{
        border-bottom: 3px solid #3B99FC;
        color: #218BFC;
    }
    .subnav_check a{
        color: #218BFC;
    }
}



// 蚂蚁日历样式处理
.ant-picker{
    height: 28px;
    min-width: 220px;
    line-height: 28px;
    border-color: #D3D9E0;
    input:focus, textarea:focus {
        border: 0 none;
    }
}
.ant-picker-focused{
    box-shadow: none !important;
}
.ant-picker-active-bar{
    margin-left: 6px !important;
}
.ant-picker-clear{
    display: none !important;
}

.icon-checkboxSeleted{
    color: #3B99FC;
}