.main{
    .masking{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999999;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background-color:rgba($color: #0F0100, $alpha: 0.7);
    }
    .modal{
        width: 380px;
        position: relative;
        .close{
            width: 40px;
            height: 39px;
            line-height: 38px;
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0.8;
            cursor: pointer;
            text-align: center;
            z-index: 99999;
            .icon_colse{
                font-size: 16px;
            }
            &:hover{
                opacity: 1;
            }
        }
        .modalContent{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            background-color: #FFF;
            // position: relative;
            // top: 50%;
            // left: 50%;
            // transform:translate(calc(-50% + 0.5px), calc(-50% + 0.5px));
            position: relative;
            z-index: 10000000;
            border: 1px solid #CED5E0;
            .header{
                width: 100%;
                padding-left: 10px;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #CED5E0;
                background: #F7F8FA;
                position: relative;
                .title{
                    position: absolute;
                    top: 0;
                    left: 10px;
                    right: 40px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .body{
                width: 100%;
                padding: 20px 20px 15px 20px;
                background: #FFF;
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                .icon_point{
                    margin-right: 3px;
                }
                .content{
                    width: 100%;
                    position: relative;
                }
            }
            .footer{
                padding: 0 20px;
                margin: 15px 0;
                >div{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
            }
        }
    }
}
