.parent {
    width: 300px;
    height: 30px;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.parent::-webkit-scrollbar {
    display: none;
}
/*设置的子盒子高度大于父盒子，产生溢出效果*/
.child {
    height: auto;
}

.child div {
    height: 30px;
    // background: #009678;
}