@charset "utf-8";
.main{
    position: relative;
    .switch{
        background: #52A362; 
        border: 1px solid #52A362; 
        border-radius: 2px; width: 56px; 
        height: 26px; 
        position: relative; 
        display: inline-block; 
        transition: 0.2s; 
        cursor: pointer;
        &:before{
            content: '\5f00'; 
            position: absolute; 
            left: 0; 
            top: 0; 
            width: 50%; 
            height: 100%; 
            font-size: 12px; 
            color: #FFFFFF; 
            line-height: 24px; 
            text-align: center;
        }
        &:after{
            content: '\5173'; 
            position: absolute; 
            right: 0; 
            top: 0; 
            width: 50%; 
            height: 100%; 
            font-size: 12px; 
            color: #FFFFFF; 
            line-height: 24px; 
            text-align: center;
        }
        b{
            position: absolute; 
            left: 32px; 
            top: 2px; 
            background: #FFFFFF; 
            border-radius: 2px; 
            width: 20px; 
            height: 20px; 
            cursor: pointer; 
            transition: 0.2s;
        }
        &.is_off{
            background: #99ABB6; 
            border-color: #83929C;
            b{
                left: 4px;
            }
        }
    }
}