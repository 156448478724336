.main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .radio_btn{
        &:last-of-type{
            margin-right: 0 !important;
        }
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        cursor: pointer;
        height: 18px;
        line-height: 18px;
        margin-right: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        .icon{
            text-align: center;
            margin-right: 5px;
            font-size: 14px;
            color: #A3B1C7;
            &.checked{
                color: #3B99FC;
            }
        }
    }
    .disable{
        opacity: .6;
        background: #FFF;
        color: #b5b5b5 !important;
        cursor: not-allowed !important;
    }
}
