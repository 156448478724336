.alink{
    position: relative;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-size: 12px;
    line-height: 18px;
    &.underline{
        text-decoration: underline;
    }
    &.blue{
        color: #218BFC;
        &:hover{
            color: #4DA2FD;
        }
    }
    &.red{
        color: #c72892;
        &:hover{
            color: #DD7EBE;
        }
    }
    &>div{
        display: none;
    }
    &:hover{
        >div{
            display: block;
        }
    }
}