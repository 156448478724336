.main{
    .stawch{
        padding: 3px;
        background: #FFF;
        width:110px;
        height:28px;
        background:rgba(255,255,255,1);
        border-radius:2px;
        border:1px solid rgba(206,213,224,1);
        cursor: pointer;
        .colorBox{
            width: 20px;
            height: 20px;
            background:rgba(0,0,0,.2);
            border-radius:2px;
            border:1px solid rgba(0,0,0,.2);;
            float: left;
        }
        .ColorPickerTit{
            float: left;
            margin-left: 5px;
            font-size: 12px;
            line-height: 20px;
        }
        
        
    }
    .pickerBox{
        position: absolute;
        z-index: 99999999;
    }
    .fixedMasking{
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}