.main{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
    list-style: none;
    position: relative;
    .tableHead{
        height: 38px;
        width: 100%;
        table{
            // min-width: 100%;
            table-layout: fixed;
            thead{
                white-space: nowrap;
                background: #F4F7FB;
                border: 1px solid #DFE7F2;
                white-space:nowrap;
                th{
                    line-height: 34px;
                    font-size: 13px;
                    color: #62778C;
                    letter-spacing: 0;
                    text-align: left;
                    height: 36px;
                    display: inline-block;
                    padding: 0 10px;
                    min-width: 46px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;   
                    .theadTitle{
                        float: left;
                    }
                    .theadExtends{
                        float: left;
                    }
                }
            }
        }
    }
    .tableBody{
        overflow: auto;
        table{
            table-layout: fixed;
            // min-width: 100%;
            // border-bottom: 1px solid #EDEDF2;
            tbody{
                tr{
                    white-space: nowrap;
                    &:hover{
                        background: #E7F4FD;
                    }
                    td{
                        border-bottom: 1px solid #EDEDF2;
                        line-height: 40px;
                        height: 40px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding: 0 10px;
                        display: inline-block;
                        min-width: 46px;
                    }
                    .events_span{
                        margin-right: 10px;
                        font-size: 12px;
                    }
                    .alink{
                        color: #218BFC;
                        cursor: pointer;
                        text-decoration: none !important;
                        &:hover{
                            color: #4DA2FD;
                        }
                    }
                    &:first-of-type{
                        display: table-row !important;
                    }
                }
            }
        }
    }
}

// 暂无数据
.nodata{
    display: none;
    cursor:default !important;
    border-bottom: 1px solid #EDEDF2;
    &:hover{
        background: none !important;
    }
    td{
        text-align: left !important;
        border-bottom: 0 none !important;
    }
}