.btn{
    display: inline-block;
    position: relative;
    .button_box{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .blue_btn{
        min-width: 48px;
        border:1px solid #3B99FC; 
        border-radius: 2px; 
        color: #FFF; 
        background: #3B99FC; 
        padding:0 10px; 
        text-align: center; 
        font-size: 14px;
        cursor: pointer;  
        &:hover{
            background: #53A5FC; 
            border-color: #53A5FC;
        }
        &:active{
            background: #358AE6; 
            border-color: #358AE6;
        }
        // 解决disable时hover问题
        &.has_loading,&.has_disable{
            &:hover{
                background: #3B99FC; 
                border-color: #3B99FC;
            }
        }
        &.has_loading,&.has_disable{
            &:active{
                background: #3B99FC; 
            border-color: #3B99FC;
            }
        }
        .load{
            color: #FFF;
        }
    }
    
    .white_btn{
        min-width: 48px;
        font-size: 14px;
        border:1px solid #CED5E0; 
        border-radius: 2px; 
        color: #28334B; 
        background: #FFF; 
        padding:0 10px; 
        text-align: center; 
        cursor: pointer; 
        &:hover{
            border-color: #3B99FC;
            color: #218BFC; 
        }
        &:active{
            border-color: #3B99FC;
            color: #358AE6; 
        }
        // 警告
        &.warn:hover{
            border-color: #F44024; 
            color: #F44024;
        }
        &.warn:active{
            border-color: #DB3920; 
            color: #DB3920;
        }
        &.has_loading,&.has_disable{
            &:hover{
                border-color:#CED5E0; 
                color: #28334B; 
            }
        }
        &.has_loading,&.has_disable{
            &:active{
                border-color:#CED5E0; 
                color: #28334B; 
            }
        }
        .load{
            color: #3B99FC;
        }
    }

    .custom_btn{
        min-width: 48px;
        font-size: 14px;
        border:1px solid #CED5E0; 
        border-radius: 2px; 
        color: #28334B; 
        background: #FFF; 
        padding:0 10px; 
        text-align: center; 
        cursor: pointer; 
        .masking{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
        }
        &:hover{
            .masking{
                display: block;
                background-color: rgba($color: #FFFFFF, $alpha: .1);
            }
        }
        &:active{
            .masking{
                display: block;
                background-color: rgba($color: #000000, $alpha: .05);
            }
        }
    }

    .btn_text{
        width: 100%;
    }
}

// 加载中
.loading{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #FFF;
    .load {
        border: 2px solid currentcolor;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: load 1s linear infinite;
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        color: #FFF;
        vertical-align: middle;
        pointer-events: none;
    }
    @keyframes load {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes load {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

// 隐藏文字部分
.text_hidden{
    visibility: hidden;
}

// 禁止点击
.disable{
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    opacity:.4;
    background-color: #FFF;
    z-index: 1;
    cursor: not-allowed;
}

.has_loading{
    cursor: not-allowed !important;
}




