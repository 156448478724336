// 下拉菜单
.bar{
    display: flex;
    justify-content:space-between;
    align-items:flex-start;
    color:#28334B;
    span{
        font-size: 12px;
    }
}

.select{
    height: 28px;
    float: right;
    cursor: pointer;
    width: 240px;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #D3D9E0;
    border-radius: 2px;
    line-height: 28px;
    &：active{
        border-color:#d3d9e0;
    }
    i{
        font-size: 14px;
    }
    .lastCheack{
        position: absolute;
        width: 100%;
        height: 100%;
        border: 0 none;
        padding: 0 20px 0 5px;
        left: 0;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        z-index: 999;
        background-color: #FFF;
        span{
            color:#8da2b5;
        }
    }

    .defaultVal{
        color: #28334B;
        float: left;
        width: 100%;
        height: 100%;
        border: 0 none;
        padding: 0 20px 0 5px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }

    i{
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        z-index: 1000;
    }
    .ulList{
        
        cursor: pointer;
        top: 29px;
        position: absolute;
        background: #FFFFFF;
        border: 1px solid #D3D9E0;
        border-radius: 2px;
        left: -1px;
        z-index: 10;
        padding: 5px 0;
        .hasChild{
            padding-left: 5px;
            padding-right: 25px;
        }
        li{
            &:first-of-type{
                display: block !important;
            }
            position: relative;
            text-align: left;
            width: 100%;
            padding: 0 10px;
            height: 24px;
            line-height: 24px;
            text-overflow:ellipsis;
            white-space:nowrap;
            .clickHandler{
                z-index: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            span{
                line-height: 24px;
                max-width: 200px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                float: left;
                width: 100%;
                text-align: left;
            }
            label{
                width: 20px;
                height: 24px;
                font-size: 14px;
                position: absolute;
                right: 5px;
                text-align: center;
                cursor: pointer;
            }
            > .listChild{
                display: none;
                position: absolute;
                top: -5px;
                left: 100%;

                cursor: pointer;
                background: #FFFFFF;
                border: 1px solid #D3D9E0;
                border-radius: 2px;
                z-index: 10;
                margin-left: 0px;
                padding: 5px 0;
            }
            
            &:hover{
                background: #E7F4FD;
                & > .listChild{
                    display: block !important;
                }
            }
            &:active{
                background: #F2F2F2;
            }
        }

        
    }
}



// 收起状态
ul.hide{
    overflow: hidden;
    display: none;
}

// li标签选中状态
li.gray{
    background: #F2F2F2 !important;
}

// 暂无数据
.nodata{
    display: none;
    cursor:default !important;
    &:hover{
        background-color: #FFF !important;
    }
}

.ulList.left{
    left: auto;
    right: -1px;
    .hasChild{
        padding-left: 25px;
        padding-right: 10px;
    }
    li{
        span{
            text-align: right;
        }
        label{
            left: 5px;
        }
        > .listChild{
            margin-left: 0;
            margin-right: 0px;
            left: auto;
            right: 100%;
        }
    }
}


