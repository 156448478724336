.loading{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 9999999;
    background-color:rgba($color: #FFF, $alpha: 0.7);
    .main{
        display: flex;
        justify-content: center;
        flex-direction:column;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #FFF;
        .load {
            border: 3px solid currentcolor;
            border-bottom-color: transparent;
            border-radius: 50%;
            animation: load 1s linear infinite;
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            color: #3B99FC;
            vertical-align: middle;
            pointer-events: none;
        }
        @keyframes load {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        @-webkit-keyframes load {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        @keyframes load {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        
    }
}

