.setting_header{
    width: 100%;
    // background: #F7F8FA;
    border-bottom: 1px solid #CED5E0;
    padding-left: 15px;
    h6{
        font-weight: 400; height: 20px; line-height: 45px; font-size: 16px;letter-spacing: 0.8px;margin: 0;
    }
    ul{
        overflow: hidden;
        li{
            float: left; margin-right: 20px; height: 35px; line-height: 30px; cursor: pointer;
            &:hover{
                border-bottom:3px solid #A3B1C7;
            }
            a{
                font-size: 12px;
                padding:12px 0; color:#434c5e;
                &:active{
                    border-bottom: 3px solid #3B99FC;color:#218BFC
                }
            }
        }
        li.subnav_check{
            border-bottom: 3px solid #3b99fc;
            color: #218bfc;
            a{
                color: #218bfc;
            }
        }
        li.disabled{
            cursor: not-allowed !important;
            &:hover{
                border-bottom:none;
            }
            a{
                color:#8DA2B5;cursor: not-allowed !important;
            }
        }
    }
}

