.main{
    padding-left: 1px;
    overflow: hidden;
    display: inline-block;
    .btnTabs{
        background-color: #FFF;
        float: left;
        display: inline-block;
        border: 1px solid #D3D9E0;
        font-size: 12px;
        height: 28px;
        line-height: 26px;
        text-align: center; 
        cursor: pointer; 
        position: relative;
        padding: 0 10px;
        margin-left:-1px;
        &:first-child{
            border-radius: 2px 0 0 2px;
        }
        &:last-child{
            border-radius: 0 2px 2px 0;
        }
        &.active{
            z-index: 1; border:1px solid #3B99FC ; color:#3B99FC;
        }
        &:hover{
            z-index: 1; border:1px solid #3B99FC ; color:#3B99FC;
        }
    }
    .subtitleTabs{
        float: left;
        cursor: pointer;
        // &:hover{
        //     border-bottom:3px solid #A3B1C7;
        // }
        &:active{
            border-bottom: 3px solid #3B99FC;color:#218BFC
        }
        &.active{
            border-bottom: 3px solid #3b99fc;
            color: #218bfc;
        }
        &.disabled{
            cursor: not-allowed !important;
            color:#8DA2B5;
            &:hover{
                border-bottom:none;
            }
        }
    }
}