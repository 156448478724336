.main{
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px 30px 15px;
    overflow-x: auto;
    .content{
        height: 100%;
        width: 100%;
        overflow-x: auto;
    }
}